<button
    mat-icon-button
    class="theme-picker-trigger"
    aria-label="Select a theme"
    [mat-menu-trigger-for]="themeMenu"
    matTooltip="主题选择"
>
    <mat-icon>format_color_fill</mat-icon>
</button>

<mat-menu #themeMenu="matMenu" xPosition="before" class="theme-picker-menu">
    <app-theme-selector />
</mat-menu>
